import React, { useState, Suspense } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useLocation,
	Outlet,
} from "react-router-dom";
import MainLayout from "../../components/layout/Main";
import Dashboard from "../../pages/Dashboard";
import Landing from "../../pages/Landing";
import userContext from "../context/Context";
import { isLoggedIn } from "../../helpers/auth";
import LoadingModal from "../../components/LoadingModal";

// import Authentication from "../../pages/Authentication";
// import ErrorPage from "../../pages/ErrorPage";
// import Files from "../../pages/Files";
// import FormInput from "../../pages/FormInput";
// import ForgotPass from "../../pages/ForgotPass";
// import FileDetails from "../../pages/FileDetails";
// import Profile from "../../pages/Profile";
// import FileInfo from "../../pages/FileInfo";
// import ResetPassword from "../../pages/ResetPassword";
// import Verify from "../../pages/Verify";

const Authentication = React.lazy(() => import("../../pages/Authentication"));
const ErrorPage = React.lazy(() => import("../../pages/ErrorPage"));
const Files = React.lazy(() => import("../../pages/Files"));
const FormInput = React.lazy(() => import("../../pages/FormInput"));
const ForgotPass = React.lazy(() => import("../../pages/ForgotPass"));
const FileDetails = React.lazy(() => import("../../pages/FileDetails"));
const Profile = React.lazy(() => import("../../pages/Profile"));
const FileInfo = React.lazy(() => import("../../pages/FileInfo"));
const ResetPassword = React.lazy(() => import("../../pages/ResetPassword"));
const Verify = React.lazy(() => import("../../pages/Verify"));
const ManageAccount = React.lazy(() => import("../../pages/ManageAccount"));

function Routers() {
	const [user, setUser] = useState(null);

	return (
		<userContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{ user }}
		>
			<Router>
				<Routes>
					<Route exact path="/" element={<Landing />} />
					<Route
						exact
						path="/auth"
						element={
							<Suspense fallback={<LoadingModal />}>
								<Authentication />
							</Suspense>
						}
					/>
					<Route
						exact
						path="/forgot-password"
						element={
							<Suspense fallback={<LoadingModal />}>
								<ForgotPass />
							</Suspense>
						}
					/>
					<Route
						exact
						path="/form"
						element={
							<Suspense fallback={<LoadingModal />}>
								<FormInput />
							</Suspense>
						}
					/>
					<Route
						exact
						path="/file-info"
						element={
							<Suspense fallback={<LoadingModal />}>
								<FileInfo />
							</Suspense>
						}
					/>
					<Route
						exact
						path="/verify"
						element={
							<Suspense fallback={<LoadingModal />}>
								<Verify />
							</Suspense>
						}
					/>
					<Route
						exact
						path="/reset-password"
						element={
							<Suspense fallback={<LoadingModal />}>
								<ResetPassword />
							</Suspense>
						}
					/>
					<Route element={<RequireAuth />}>
						<Route element={<MainLayout />}>
							<Route
								path="profile"
								element={
									<Suspense fallback={<LoadingModal />}>
										<Profile />
									</Suspense>
								}
							/>
							<Route
								path="files"
								element={
									<Suspense fallback={<LoadingModal />}>
										<Files />
									</Suspense>
								}
							/>
							<Route
								path=":id"
								element={
									<Suspense fallback={<LoadingModal />}>
										<FileDetails />
									</Suspense>
								}
							/>
							<Route
								path="manage-account"
								element={
									<Suspense fallback={<LoadingModal />}>
										<ManageAccount />
									</Suspense>
								}
							/>
						</Route>
					</Route>
					<Route
						path="*"
						element={
							<Suspense fallback={<LoadingModal />}>
								<ErrorPage />
							</Suspense>
						}
					/>
				</Routes>
			</Router>
		</userContext.Provider>
	);
}

function RequireAuth() {
	const location = useLocation();
	if (!isLoggedIn()) {
		return <Navigate to="/auth" state={{ from: location }} />;
	}
	return <Outlet />;
}

export default Routers;
