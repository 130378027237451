/* eslint-disable react/jsx-no-bind */
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
	Navbar,
	NavbarBrand,
	NavbarToggler,
	Collapse,
	Nav,
	NavItem,
} from "reactstrap";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PertaminaLogo from "../../assets/Group.svg";
import { isAuthorized } from "../../helpers/auth";

const useStyles = makeStyles({
	icon: {
		color: "#5C5C5C",
		fontWeight: 700,
		marginRight: "20px",
		cursor: "pointer",
		transition: "all .2s ease-in-out",
		"&:hover": {
			transform: "scale(1.2)",
			color: "#5C5C5C",
		},
	},
	fontIcon: {
		marginRight: "3px",
	},
	pertaminaLogo: {
		height: 40,
		objectFit: "cover",
	},
});

function Sidebar({ ...props }) {
	const navigate = useNavigate();
	const classes = useStyles();
	const location = useLocation();

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("user");
		navigate("/");
	};

	return (
		<div>
			<Navbar
				style={{
					backgroundColor: "#FFFFFF",
					boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
				}}
				expand="md"
			>
				<NavbarBrand href="/">
					<img
						src={PertaminaLogo}
						alt=""
						className={classes.pertaminaLogo}
					/>
				</NavbarBrand>
				<NavbarToggler onClick={function noRefCheck() {}} />
				<Collapse navbar>
					<Nav className="me-auto" navbar>
						{/* <NavItem>
							<Link
								to="/dashboard"
								style={{
									textDecoration: "none",
									[location.pathname === "/dashboard" &&
									"color"]: "#224d9c",
								}}
								className={classes.icon}
							>
								<FontAwesomeIcon
									icon={faGauge}
									size="lg"
									className={classes.fontIcon}
								/>
								Dashboard
							</Link>
						</NavItem> */}
						<NavItem>
							<Link
								to="/profile"
								style={{
									textDecoration: "none",
									[location.pathname === "/profile" &&
									"color"]: "#224d9c",
								}}
								className={classes.icon}
							>
								<Badge color="secondary" overlap="rectangular">
									<AccountCircleIcon />
								</Badge>
								Profile
							</Link>
						</NavItem>

						<NavItem>
							<Link
								to="/files"
								style={{
									textDecoration: "none",
									[location.pathname === "/files" && "color"]:
										"#224d9c",
								}}
								className={classes.icon}
							>
								<Badge color="secondary" overlap="rectangular">
									<MailIcon />
								</Badge>
								Files
							</Link>
						</NavItem>
						{isAuthorized(1) && (
							<NavItem>
								<Link
									to="/manage-account"
									style={{
										textDecoration: "none",
										[location.pathname ===
											"/manage-account" && "color"]:
											"#224d9c",
									}}
									className={classes.icon}
								>
									<Badge
										color="secondary"
										overlap="rectangular"
									>
										<SupervisorAccountIcon />
									</Badge>
									Manage Account
								</Link>
							</NavItem>
						)}
					</Nav>
				</Collapse>
				<Tooltip title="Logout">
					<IconButton
						className={classes.icon}
						color="primary"
						aria-label="upload picture"
						component="span"
						onClick={() => {
							handleLogout();
						}}
					>
						<ExitToAppIcon />
					</IconButton>
				</Tooltip>
			</Navbar>
		</div>
	);
}

export default Sidebar;
