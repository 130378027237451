import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";

function LoadingModal() {
	return (
		<Fade in>
			<CircularProgress
				size={40}
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					color: "#224d9c",
				}}
			/>
		</Fade>
	);
}

export default LoadingModal;
