import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import styles from "../styles/pages/landing.module.css";
import { isLoggedIn } from "../helpers/auth";
import LogoPertamina from "../assets/Group.svg";
import LogoBumn from "../assets/Logo_BUMN_Untuk_Indonesia_2020.svg";
import PertaminaPeople from "../assets/landingbg.svg";

const useStyles = makeStyles(() => ({
	btn: {
		margin: "10px 0",
		width: "170px",
		"@media (max-width: 320px)": {
			margin: 0,
		},
	},
	pertaminaLogo: {
		position: "absolute",
		width: 150,
		top: 10,
		right: 10,
		"@media (max-width: 481px)": {
			width: 120,
		},
		"@media (max-width: 414px)": {
			width: 100,
		},
		"@media (max-width: 375px)": {
			width: 90,
		},
	},
	bumnLogo: {
		position: "absolute",
		width: 150,
		top: "15px",
		left: 10,
		"@media (max-width: 481px)": {
			width: 120,
		},
		"@media (max-width: 414px)": {
			width: 100,
		},
		"@media (max-width: 375px)": {
			width: 90,
		},
	},
	pertaminaPeople: {
		position: "fixed",
		bottom: -100,
		right: 0,
		width: 550,
		zIndex: -1,
		"@media (max-width: 641px)": {
			width: 450,
		},
		"@media (max-width: 481px)": {
			width: 380,
		},
		"@media (max-width: 375px)": {
			width: 300,
			bottom: -30,
		},
	},
}));

function Landing() {
	const classes = useStyles();
	return (
		<div className={styles.container}>
			<img src={LogoPertamina} alt="" className={classes.pertaminaLogo} />
			<img src={LogoBumn} alt="" className={classes.bumnLogo} />
			<img
				src={PertaminaPeople}
				alt="pertamina-people"
				className={classes.pertaminaPeople}
			/>
			<div className={styles["title-sect"]}>
				<h2 className={styles["text-center"]}>
					Selamat Datang di Virtual Gate Pass
				</h2>
				<h2 className={styles["text-center"]}>
					PT. Pertamina Patra Niaga Fuel Terminal Rewulu
				</h2>
				<br />
				<p className={styles["text-center"]}>
					Silahkan mengisi{" "}
					<span style={{ fontWeight: "bold" }}>
						SURAT IZIN MEMBAWA MASUK / KELUAR BARANG MATERIAL /
						ASSET
					</span>{" "}
					<br />
					melalui link dibawah ini :
				</p>
				<Link to="/form" style={{ textDecoration: "none" }}>
					<Button
						className={classes.btn}
						variant="contained"
						color="primary"
					>
						buat surat
					</Button>
				</Link>
			</div>
			<div className={styles["title-sect"]}>
				<p className={styles["text-center"]}>
					Anda dapat mengetahui informasi status surat melalui link
					dibawah :
				</p>
				<Link to="/file-info" style={{ textDecoration: "none" }}>
					<Button
						className={classes.btn}
						variant="contained"
						color="primary"
					>
						cek status surat
					</Button>
				</Link>
			</div>
			<div className={styles["btn-group"]}>
				<p className={styles["text-center"]}>Approval / Rejection</p>
				{isLoggedIn() ? (
					<Link to="/files">
						<Button
							className={classes.btn}
							variant="contained"
							color="primary"
						>
							ke halaman file
						</Button>
					</Link>
				) : (
					<Link to="/auth" style={{ textDecoration: "none" }}>
						<Button
							className={classes.btn}
							variant="contained"
							color="primary"
							style={{ backgroundColor: "#fcc705" }}
						>
							Login
						</Button>
					</Link>
				)}
			</div>
		</div>
	);
}

export default Landing;
