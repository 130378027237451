import { hot } from "react-hot-loader/root";
import Routers from "./config/router/Routers";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
	console.log("gatepass-app version: 2.0.0 is running");
	return <Routers />;
}

export default hot(App);
