import { Outlet } from "react-router-dom";
import Navbar from "../Navbar";

function Main() {
	return (
		<div
			style={{
				backgroundColor: "#F3F7FB",
				minHeight: "100vh",
			}}
		>
			<Navbar />
			<main className="main-section">
				<Outlet />
			</main>
		</div>
	);
}

export default Main;
