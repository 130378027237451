export const isAuth = (role) => {
	const stringObj = localStorage.getItem("user");
	if (stringObj) {
		const user = JSON.parse(stringObj);
		if (user.role === role) return true;
	}
	return false;
};

export const checkRole = () => {
	const stringObj = localStorage.getItem("user");
	let user;
	if (stringObj) {
		user = JSON.parse(stringObj);
	}
	switch (user.role) {
		case "admin":
			return "admin";
		case "spvmps":
			return "mps";
		case "spvhse":
			return "hse";
		case "ftm":
			return "ftm";
		case "security":
			return "security";
		default:
			return "admin";
	}
};

export const checkAuthorized = () => {
	try {
		const user = JSON.parse(localStorage.getItem("user"));
		return user.authorized;
	} catch (err) {
		console.error(err);
		return null;
	}
};

export const isAuthorized = (auth) => {
	try {
		const user = JSON.parse(localStorage.getItem("user"));
		if (auth === user.authorized && user.active === 1) {
			return true;
		}
		return false;
	} catch (err) {
		console.error(err);
		return false;
	}
};

export const isLoggedIn = () => {
	if (localStorage.getItem("token")) {
		return true;
	}
	return false;
};
